import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { REVOKE_SESSION_MUTATION } from './useRevokeSession.graphql';
import {
  IRevokeSessionMutationGraphQLResponse,
  IUseRevokeSessionOptions,
  IUseRevokeSession,
  IRevokeSessionMutationVariables,
  IHandleRevokeSessionParams
} from './useRevokeSession.interfaces';

const useRevokeSession = (options?: IUseRevokeSessionOptions): IUseRevokeSession => {
  const { mutation, onCompleted, onError, token, product } = options ?? ({} as IUseRevokeSessionOptions);

  const [executeRevokeSession, { loading: isLoading }] = useLoggedGeladaMutation<
    IRevokeSessionMutationGraphQLResponse,
    IHandleRevokeSessionParams
  >({
    mutation: mutation ?? REVOKE_SESSION_MUTATION,
    options: {
      onCompleted: ({ accessToken: { revokeSession: isCompleted } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
    product,
  });

  const handleRevokeSession = async ({ refreshToken }: IRevokeSessionMutationVariables) => {
    await executeRevokeSession({
      variables: {
        refreshToken,
      }
    });
  };

  return {
    handleRevokeSession,
    isLoading,
  };
};

export { useRevokeSession };
