import { NetfrontProductType } from '@netfront/common-library';

import { PAGE_CONFIG_DEVELOPMENT, PAGE_CONFIG_PRODUCTION, PAGE_CONFIG_LOCAL } from '../constants';
import { ProductPageConfig } from '../types';

const getProductConfig = (product: NetfrontProductType): ProductPageConfig => {
  switch (String(process.env.REACT_APP_ENVIRONMENT)) {
    case 'LOCAL':
      return PAGE_CONFIG_LOCAL[product] as ProductPageConfig;
    case 'DEVELOPMENT':
      return PAGE_CONFIG_DEVELOPMENT[product] as ProductPageConfig;
    case 'PRODUCTION':
      return PAGE_CONFIG_PRODUCTION[product] as ProductPageConfig;
    default:
      return PAGE_CONFIG_PRODUCTION[product] as ProductPageConfig;
  }
};

export { getProductConfig };
